import React from 'react'
import Layout from '../components/Layout/Layout'
import Navigation from '../components/Navigation/Navigation'
import Seo from '../components/Seo/Seo'
import Posts from '../components/Posts/Posts';
import config from '../../config.json';
import '../scss/pages/post.scss';
import StickyBox from '../components/StickyBox/StickyBox';

export default function Ranking({pageContext}) {
    const heading = pageContext.data.title
    const content = pageContext.data.content
    const seo = pageContext.data.seo
    const nnWidgetArticles = [
        'nationale-nederlanden-razem-dla-siebie-2'
    ]
    
    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl}/>
            <div className="post-page">
                <div className="header">
                    <Navigation/>
                    <div className="custom-container">
                        <h1 class="text-center mt-50 mb-50 title">{heading}</h1>
                        <a class="cta-radius referrer-link">Dopasuj ubezpieczenie</a>
                    </div>
                </div>
                <div className="custom-container post-content-container">
                    <div className="row">
                        <div class="col-lg-8">
                            {pageContext.data.featuredImage && (
                                <img className="post-image" src={pageContext.data.featuredImage.node.sourceUrl} title={heading} />
                            )}

                            <div class="post-content" dangerouslySetInnerHTML={{ __html: content }}></div>
                        </div>
                        <div className="col-lg-4">
                            {nnWidgetArticles.includes(pageContext.data.slug) && (
                                <div class="nn-contact-form">
                                    <iframe src="https://lp.nn.pl/leadguru" width="100%" height="660" scrolling="no"></iframe>
                                </div>
                            )}
                            <StickyBox />
                        </div>
                    </div>
                </div>
                <Posts content="Już od wielu lat zajmujemy się zarówno samą tematyką ubezpieczeniową, jak i skuteczną komunikacją pomiędzy klientami, a agentami i towarzystwami ubezpieczeniowymi. Ubezpieczenia grupowe pracowników to obszerna dziedzina. Dokładamy starań, aby poszczególne składające się na nią zagadnienia przybliżać w przystępny i zarazem wyczerpujący sposób. Zarówno pracownikom, jak i pracodawcom poszukującym rozwiązań, dzięki którym zyskają w ich oczach ważny atut. Zapraszamy do częstych odwiedzin na naszym blogu."/>
            </div>
        </Layout>
    )
}
